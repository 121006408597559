<template>
  <div class="shadow-gray-500 shadow-lg" @click="enlarge">
    <div v-if="images.length > 1" ref="container">
      <agile :autoplay="true" :navButtons="false" class="w-full" :dots="dots">
        <div v-for="image in images" :key="image.id" class="slide" ref="slides">
          <image-core :image="image" />
        </div>
      </agile>
    </div>
    <div v-else>
      <image-core :image="images[0]" />
    </div>
  </div>
</template>

<script>
  import { VueAgile } from 'vue-agile'
  import ImageCore from '@/Shared/ImageCore.vue'
  import 'viewerjs/dist/viewer.css'

  export default
  {
    components: {
      agile: VueAgile,
      ImageCore
    },
    props: {
      images: {
        type: Array
      },
      dots: {
        type: Boolean,
        default: true
      }
    },
    watch: {
      images: function(newVal, oldVal) {
        this.updateLarge();
      }
    },
    data() {
      return {
        large: []
      }
    },
    created() {
      this.updateLarge();
    },
    methods: {
      updateLarge() {
        var that = this;
        that.large = [];
        this.images.forEach((element) => {
          if (element.link) {
            that.large.push('/images/' + element.name + '.jpg')
          }
        });
      },
      enlarge () {
        if (this.large.length > 0)
        {
          this.$viewerApi({
            images: this.large,
            options: {movable: false}
          })
        }
      }
    }
  }
</script>
