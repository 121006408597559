<template>
  <!-- use createImageCoreImages.bat to create images in all sizes -->
  <div>
    <lazy-component v-if="image.url">
      <picture class="w-full sm:h-64 mx-auto">
        <img :src="image.url" :alt="image.alt">
      </picture>
    </lazy-component>
    <lazy-component v-else>
      <picture class="w-full sm:h-64 mx-auto" viewBox="0 0 1177 598.5">
        <source :srcset="imgBase + '-600w.webp 600w, ' + imgBase + '-480w.webp 480w, ' + imgBase + '-400w.webp 400w, ' + imgBase + '-300w.webp 300w'"
                type="image/webp">
        <source :srcset="imgBase + '-600w.jpg  600w, ' + imgBase + '-480w.jpg  480w, ' + imgBase + '-400w.jpg  400w, ' + imgBase + '-300w.jpg  300w'"
                type="image/jpeg">
        <img :src="imgBase + '-600w.jpg'" :alt="image.alt">
      </picture>
    </lazy-component>
  </div>
</template>

<script>
  export default
  {
    props: ['image'],
    computed: {
      imgBase() {
        return '/images/' + this.image.name;
      }
    },
  }
</script>
